import { Box } from "@mui/system";
import React from "react";
import Body from "../../common/body/Body";
import FanGoButton from "../../common/button/FanGoButton";
import styles from "../../common/styling/styles";
import { MainText, SubText } from "../../common/texts";

const PriceList = ({ data }) => {
  const { title, text, buttonName } = data;

  const CTAButtonName = (
    <MainText
      textVariant="h4"
      value={buttonName}
      paddingX={styles.allZero}
      marginY={styles.allZero}
    />
  );

  return (
    <Body>
      <MainText textVariant="h2" fontColor={styles.darkBlue} value={title} />

      <SubText
        variant="subtitle1"
        fontColor={styles.grey}
        value={text}
        textAlignment={styles.alignStyleJJJJ}
      />
      <Box sx={{ textAlign: "center", py: 2 }}>
        <FanGoButton buttonName={CTAButtonName} link="/pricing/" />
      </Box>
    </Body>
  );
};

export default PriceList;
