import { graphql } from 'gatsby';
import React, { useContext } from 'react';

import StandardSEO from '../components/common/seo/StandardSEO';
import Separator from '../components/common/styling/Separator';
import styles from '../components/common/styling/styles';
import { getBackgroundImageGatsbyData } from '../components/componentUtils';
import { SimplePageHead, TrustedBrandIcons } from '../components/PageComps/CommonComps';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import EventPlanning from '../components/PageComps/EventPlanningPageComps/EventPlanning';
import PlatformFeaturesCarousel from '../components/PageComps/EventPlanningPageComps/PlatformFeaturesCarousel';
import PriceList from '../components/PageComps/EventPlanningPageComps/PriceList';
import WhyChooseUs from '../components/PageComps/EventPlanningPageComps/WhyChooseUs';
import { DataContext } from '../providers/DataProvider';

const OurDevelopmentPage = ({ data }) => {
  const { sanityEventManagementSoftwarePage } = data;
  const { heroSectionBackgroundImage: imageData } = useContext(DataContext);

  const { pageHead, ButtonName } = sanityEventManagementSoftwarePage;

  const { title, subTitle } = pageHead;

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="5% 90%"
        imageData={imageData}
        content={
          <BasicTitleSubTitle
            title={title}
            subTitle={subTitle}
            subTitlePlainText={true}
            titleRowSpacing={undefined}
            titlePaddingBottom={40}
            largeSubTitleSize
          />
        }
      />

      <EventPlanning data={sanityEventManagementSoftwarePage.eventPlanning} />

      <PlatformFeaturesCarousel
        data={sanityEventManagementSoftwarePage.platformFeatures}
      />

      <PriceList data={sanityEventManagementSoftwarePage.softwareCost} />

      <WhyChooseUs data={sanityEventManagementSoftwarePage.whyChooseUs} />

      <Separator height={100} />

      <TrustedBrandIcons
        data={data.sanityCommonContent.trustedBy}
        paddingBottom={styles.allEight}
      />
    </>
  );
};

export default OurDevelopmentPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityEventManagementSoftwarePage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityEventManagementSoftwarePage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        subTitle
      }
      ButtonName
      eventPlanning {
        title
        subTitle
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 500)
          }
          altText
        }
        managementFeatureList {
          feature
          text: _rawDescription
        }
        christmasTicketImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 500)
          }
          altText
        }
      }
      platformFeatures {
        title
        platformFeatures {
          _key
          Icon
          feature
          description
        }
      }
      softwareCost {
        title
        text
        buttonName
      }
      whyChooseUs {
        title
        text
        firstButtonName
        secondButtonName
      }
    }
    sanityCommonContent(language: { eq: $language }) {
      trustedBy {
        title
      }
    }
  }
`;
