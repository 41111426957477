import React, { useContext } from "react";
import { Button } from "@mui/material";
import { DataContext } from "../../../providers/DataProvider";
import { getLink } from "../../../utils/getLink";

const FanGoButton = ({
  buttonName,
  link = null,
  boxShadow = null,
  fontSize = 15,
  buttonWidth = null,
  variant = null,
  startIcon = null,
  color = null,
  lineHeight = null,
  formatLink = true,
}) => {
  const { pageContext } = useContext(DataContext);

  const buttonStyle = {
    textTransform: "initial !important",
    boxShadow: boxShadow || "0 3px 10px rgb(0 0 0 / 0.2)",
    fontSize: fontSize,
    width: buttonWidth || null,
    lineHeight: lineHeight,
  };

  const buttonHref = () => {
    if (!link) {
      return;
    }

    if (!formatLink) {
      return link;
    }

    const slash = "/";

    const key = link.replace(new RegExp(slash, "g"), "");

    return getLink({ key, pageContext }) || undefined;
  };

  return (
    <Button
      href={buttonHref()}
      variant={variant || "contained"}
      color={color || "secondary"}
      size="large"
      sx={buttonStyle}
      startIcon={startIcon || undefined}
    >
      {buttonName}
    </Button>
  );
};

export default FanGoButton;
