import { Box } from '@mui/material';
import React from 'react';
import Feature from './Feature';

export const productFeaturesCard = (array) => {
  const cardList = array.map((card) => (
    <Box sx={{ p: 1.5, height: '100%' }}>
      <Feature data={card} key={card._key} />
    </Box>
  ));

  return cardList;
};
