import { Box } from "@mui/material";
import React from "react";
import Body from "../../common/body/Body";
import { MainText } from "../../common/texts";
import { productFeaturesCard } from "../../features/FeaturesCard";
import { FanGoGridCarousel } from "../../common/carousel";
import styles from "../../common/styling/styles";
import { useStaticQuery, graphql } from "gatsby";

const responsiveSizes = [
  {
    breakpoint: 1100,
    cols: 2,
    rows: 2,
  },
  {
    breakpoint: 850,
    cols: 1,
    rows: 2,
  },
  {
    breakpoint: 600,
    cols: 1,
    rows: 1,
  },
];

const PlatformFeaturesCarousel = ({ data }) => {
  const cardArray = productFeaturesCard(data.platformFeatures);

  return (
    <Body marginY={styles.allSix} backgroundColor={styles.lightBlue}>
      <Box>
        <MainText
          fontColor={styles.darkBlue}
          textVariant="h2"
          value={data.title}
        />
        <Box>
          <Box sx={{ pt: 5 }}>
            <FanGoGridCarousel
              array={cardArray}
              cols={3}
              rows={2}
              mobileBreakpoint={10}
              responsiveLayout={responsiveSizes}
            />
          </Box>
        </Box>
      </Box>
    </Body>
  );
};

export default PlatformFeaturesCarousel;
