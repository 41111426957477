import { Box } from "@mui/system";
import React from "react";
import Body from "../../common/body/Body";
import FanGoButton from "../../common/button/FanGoButton";
import styles from "../../common/styling/styles";
import { MainText, SubText } from "../../common/texts";
import ContactButton from "../../common/button/ContactButton";

const WhyChooseUs = ({ data }) => {
  const { title, text, firstButtonName, secondButtonName } = data;

  const CTAFirstButtonName = (
    <MainText
      textVariant="h4"
      value={firstButtonName}
      paddingX={styles.allZero}
      marginY={styles.allZero}
    />
  );

  const CTASecondButtonName = (
    <MainText
      textVariant="h4"
      value={secondButtonName}
      paddingX={styles.allZero}
      marginY={styles.allZero}
    />
  );

  return (
    <Body backgroundColor={styles.darkBlue}>
      <MainText textVariant="h2" value={title} />

      <SubText
        variant="subtitle1"
        value={text}
        textAlignment={styles.alignStyleJJJJ}
      />
      <Box
        sx={{
          display: { xs: "flex", md: "flex" },
          flexDirection: "row",
          justifyContent: "center",
          py: 3,
        }}
      >
        <Box sx={{ mr: 3 }}>
          <FanGoButton
            buttonName={CTAFirstButtonName}
            variant="outlined"
            color="neutral"
            link="/clients/"
          />
        </Box>
        <Box>
          <ContactButton buttonName={CTASecondButtonName} />
        </Box>
      </Box>
    </Body>
  );
};

export default WhyChooseUs;
