import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Body from "../../common/body/Body";
import styles from "../../common/styling/styles";
import { MainText, SubText } from "../../common/texts";
import FeatureText from "./FeatureText";

const textBoxStyle = {
  width: "60%",
  height: "100%",
  display: { xs: "none", md: "flex" },
  flexDirection: "column",
  py: 5,
};

const EventPlanning = ({ data }) => {
  const {
    title,
    subTitle,
    image,
    managementFeatureList,
    christmasTicketImage,
  } = data;

  return (
    <Body paddingY={styles.allFifteen}>
      <MainText textVariant="h2" fontColor={styles.darkBlue} value={title} />
      <SubText
        variant="subtitle1"
        fontColor="grey.900"
        textAlignment={styles.alignStyleLLLL}
        value={subTitle}
      />

      <Box
        sx={{
          display: { xs: "block", md: "flex" },
        }}
      >
        <Box
          sx={{
            ...textBoxStyle,
            width: "60%",
            display: { xs: "none", md: "flex" },
          }}
        >
          {managementFeatureList.map((eventFeature, index) => {
            return <FeatureText key={index} feature={eventFeature} />;
          })}
        </Box>

        <Box
          sx={{
            width: { xs: "80%", md: "40%" },
            height: "100%",
            overflow: "auto",
            position: { md: "sticky" },
            top: "5%",
            py: 5,
            ml: 5,
          }}
        >
          <Box sx={{ textAlign: "center", py: 5 }}>
            <GatsbyImage
              image={image.asset.gatsbyImageData}
              alt={image.altText || ""}
            />
          </Box>

          <Divider sx={{ my: 5 }} />

          <Box sx={{ textAlign: "center", py: 5 }}>
            <GatsbyImage
              image={christmasTicketImage.asset.gatsbyImageData}
              alt={christmasTicketImage.altText || ""}
            />
          </Box>
        </Box>
        <Box
          sx={{
            ...textBoxStyle,
            width: "100%",
            display: { xs: "block", md: "none" },
          }}
        >
          {managementFeatureList.map((eventFeature, index) => {
            return <FeatureText key={index} feature={eventFeature} />;
          })}
        </Box>
      </Box>
    </Body>
  );
};

export default EventPlanning;
