import React, { useContext } from 'react';

import { Box, Typography } from '@mui/material';

import { DataContext } from '../../../providers/DataProvider';

const GreenFanGoLinkButton = ({ onClick, buttonText }) => {
  const { setShowDemoDialog, handleDrawerToggle, mobileOpen, pathname } =
    useContext(DataContext);

  const handleButtonClick = () => {
    onClick();

    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <button
        style={{
          backgroundColor: '#47b19a',
          padding: '10px 30px',
          borderRadius: 5,
          color: 'white',
          fontSize: 18,
          border: 0,
          background: 'linear-gradient(45deg, #47b19a 30%, #057058 90%)',
          position: 'relative',
          overflow: 'hidden',
          cursor: 'pointer',
        }}
        onClick={handleButtonClick}
      >
        <Typography sx={{ fontWeight: 'bold', color: 'white' }}>
          {buttonText}
        </Typography>
      </button>
    </Box>
  );
};

export default GreenFanGoLinkButton;
