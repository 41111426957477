import { Box } from '@mui/material';
import React from 'react';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';

const FeatureText = ({ feature }) => {
  return (
    <Box>
      <MainText
        textVariant="h3"
        fontColor={styles.deepGreen}
        paddingX={styles.allZero}
        textAlignment={styles.alignStyleLLLL}
        value={feature.feature}
      />

      <SubText
        variant="subtitle1"
        fontColor={styles.grey}
        textAlignment={styles.alignStyleLLJJ}
        value={<FanGoTextProcessor text={feature.text} />}
      />
    </Box>
  );
};

export default FeatureText;
