import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React from 'react';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import AnimatedScrollDownButton from '../../HomePageComps/AnimatedScrollDownButton';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const FullImageBackgroundHeader = ({
  height = '100%',
  imageData,
  content,
  backgroundPosition = '30% 50%',
  showScrollDownButton = false,
  hideOverflowX = true,
  shadow = '0.4',
  maxHeight = '800px',
}) => {
  const image = getImage(imageData);
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      style={{
        maxHeight: maxHeight,
        minHeight: '500px',
        width: '100%',
        backgroundPosition: backgroundPosition,
        backgroundColor: 'white',
        height: height,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        overflowX: hideOverflowX ? 'hidden' : 'visible',
      }}
      {...bgImage}
    >
      <div style={{ backgroundColor: `rgba(0, 0, 0, ${shadow})` }}>
        {content}
      </div>
      {showScrollDownButton && <AnimatedScrollDownButton />}
    </BackgroundImage>
  );
};

export default FullImageBackgroundHeader;
